<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title
            class="elevation-1"
            style="font-family: poppinsextrabold"
          >
            Module Details
            <v-spacer></v-spacer>
            <!-- <v-flex xs6 text-right>
              <v-btn
                class="mr-2"
                color="green"
                :to="'/question?id=' + $route.query.id"
              >
                <span style="color: white"> Questions </span>
              </v-btn>
            </v-flex> -->
          </v-card-title>
          <div class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" v-if="moduleView.moduleNo">
                    <span class="item_title"
                      >Module {{ moduleView.moduleNo }} :
                      {{ moduleView.moduleName }}</span
                    >
                    <hr />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" md="12">
                    <span
                      class="item_value"
                      v-html="moduleView.moduleDetails"
                    ></span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- <v-layout wrap pt-5>
              <v-flex>
                <v-card-title
                  class="elevation-1"
                  style="font-family: poppinsextrabold"
                >
                  Exam Settings
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6 md4 pa-2 pt-10 v-if="examDetails">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm4 md10 text-right pr-2>
                        <span
                          style="font-size: 15px; font-family: poppinsmedium"
                          >Change Status</span
                        >
                      </v-flex>
                      <v-flex xs12 sm2 md2 text-left>
                        <v-btn
                          color="green"
                          small
                          depressed
                          @click="statusChange()"
                          ><span
                            style="color: white; font-family: poppinsmedium"
                            >{{ examDetails.status }}</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    md4
                    text-right
                    v-if="data.isExamAdded == false"
                  >
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="800px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add Exam Settings
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Details</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.totalNumberOfQuestions"
                                    label="Total Number of Questions"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      exam.durationOfEachQuestionInMinutes
                                    "
                                    label="Duration of Each Question in Minutes"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      exam.passPercentageForGradeCalculation
                                    "
                                    label="Pass Percentage for Grade Calculation"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.numberOfAttemptsPerStudent"
                                    label="Number of Attempts Per Student"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-checkbox
                                    v-model="
                                      exam.isRandomOrderOfCourceQuestionSelectionEnabled
                                    "
                                    label="Is Random Order of Course Question Selection Enabled"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.markPerQuestions"
                                    label="Mark per Questions"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.numberofMcqandTrueorFalse"
                                    label="Number of MCQ and True/False question"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.numberOfEassyQuestions"
                                    label="Number of essay question"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.passMark"
                                    label="Pass Mark"
                                    required
                                    :rules="[rules.required]"
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-checkbox
                                    v-model="exam.enableNegativeMarkingOption"
                                    label="Enable Negative Marking Option"
                                  ></v-checkbox>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  md="12"
                                  v-if="
                                    exam.enableNegativeMarkingOption == true
                                  "
                                >
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="exam.negativeMarkDeduction"
                                    label="Negative Mark Deduction"
                                    required
                                    type="number"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="add()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-card-title>
                <v-layout wrap pt-5 pa-1 v-if="examDetails">
                  <v-flex xs12 sm6 v-if="examDetails.totalNumberOfQuestions">
                    <span style="font-family: poppinssemibold"
                      >Total Number of Questions :
                      {{ examDetails.totalNumberOfQuestions }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.durationOfEachQuestionInMinutes"
                  >
                    <span style="font-family: poppinssemibold"
                      >Duration of Each Question in Minutes :
                      {{ examDetails.durationOfEachQuestionInMinutes }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.passPercentageForGradeCalculation"
                  >
                    <span style="font-family: poppinssemibold"
                      >Pass Percentage for Grade Calculation :
                      {{ examDetails.passPercentageForGradeCalculation }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="
                      examDetails.isRandomOrderOfCourceQuestionSelectionEnabled
                    "
                  >
                    <span style="font-family: poppinssemibold"
                      >Is Random Order of Course Question Selection Enabled :
                      {{
                        examDetails.isRandomOrderOfCourceQuestionSelectionEnabled
                      }}</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 v-if="examDetails.markPerQuestions">
                    <span style="font-family: poppinssemibold"
                      >Mark per Questions :
                      {{ examDetails.markPerQuestions }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.enableNegativeMarkingOption"
                  >
                    <span style="font-family: poppinssemibold"
                      >Enable Negative Marking Option :
                      {{ examDetails.enableNegativeMarkingOption }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.enableNegativeMarkingOption"
                  >
                    <span style="font-family: poppinssemibold"
                      >Negative Mark Deduction :
                      {{ examDetails.negativeMarkDeduction }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.numberOfAttemptsPerStudent"
                  >
                    <span style="font-family: poppinssemibold"
                      >Number of Attempts Per Student :
                      {{ examDetails.numberOfAttemptsPerStudent }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.numberOfMCQuestions"
                  >
                    <span style="font-family: poppinssemibold"
                      >Number of MCQ and True/False Question :
                      {{ examDetails.numberOfMCQuestions }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.numberOfEassyQuestions"
                  >
                    <span style="font-family: poppinssemibold"
                      >Number of Essay Question :
                      {{ examDetails.numberOfEassyQuestions }}</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    v-if="examDetails.passMark"
                  >
                    <span style="font-family: poppinssemibold"
                      >Pass Mark :
                      {{ examDetails.passMark }}</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end pt-5 pa-1 v-if="examDetails">
                  <v-flex xs12 sm6 md2 pa-2 pt-10 text-right>
                    <v-btn
                      block
                      small
                      style="font-family: poppinssemibold"
                      color="green"
                      @click="editdialog = true"
                      dark
                      >EDIT</v-btn
                    >
                  </v-flex>
                  <v-flex xs6 sm4 md2 pa-2 pt-10 text-right>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          block
                          small
                          style="font-family: poppinssemibold"
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          DELETE
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this exam
                          settings?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogDelete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem()"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout> -->
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Exam Settings</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap justify-center v-if="examDetails">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.totalNumberOfQuestions"
                label="Total Number of Questions"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.durationOfEachQuestionInMinutes"
                label="Duration of Each Question in Minutes"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.passPercentageForGradeCalculation"
                label="Pass Percentage for Grade Calculation"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.numberOfAttemptsPerStudent"
                label="Number of Attempts Per Student"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="
                  examDetails.isRandomOrderOfCourceQuestionSelectionEnabled
                "
                label="Is Random Order of Course Question Selection Enabled"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.markPerQuestions"
                label="Mark per Questions"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.numberOfMCQuestions"
                label="Number of MCQ and True/False question"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.numberOfEassyQuestions"
                label="Number of essay question"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="examDetails.passMark"
                label="Pass Mark"
                required
                :rules="[rules.required]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="examDetails.enableNegativeMarkingOption"
                label="Enable Negative Marking Option"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="examDetails.enableNegativeMarkingOption == true"
            >
              <v-text-field
                outlined
                dense
                v-model="examDetails.negativeMarkDeduction"
                label="Negative Mark Deduction"
                required
                type="number"
              ></v-text-field>
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      data: [],
      question: "",
      examDetails: [],
      editdialog: false,
      editingitem: {},
      radioGroup: "",
      addcat: false,
      dialogDelete: false,
      rules: {
        required: (value) => !!value || "Required.",
        max: (v) =>
          v >= 100 || "Percentage value should not be greater than 100",
      },
      exam: {
        totalNumberOfQuestions: "",
        durationOfEachQuestionInMinutes: "",
        passPercentageForGradeCalculation: "",
        isRandomOrderOfCourceQuestionSelectionEnabled: false,
        markPerQuestions: "",
        numberofMcqandTrueorFalse: "",
        numberOfEassyQuestions: "",
        passMark: "",
        enableNegativeMarkingOption: false,
        negativeMarkDeduction: "",
        numberOfAttemptsPerStudent: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/module/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.moduleView = response.data.module;
          this.data = response.data;
          this.examDetails = response.data.examSetting;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.appLoading = true;
      this.exam.moduleId = this.$route.query.id;
      axios({
        url: "/setting/exam/add",
        method: "POST",
        data: this.exam,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.dialog = false;
            this.question = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["totalNumberOfQuestions"] = this.examDetails.totalNumberOfQuestions;
      user["durationOfEachQuestionInMinutes"] =
        this.examDetails.durationOfEachQuestionInMinutes;
      user["passPercentageForGradeCalculation"] =
        this.examDetails.passPercentageForGradeCalculation;
      user["isRandomOrderOfCourceQuestionSelectionEnabled"] =
        this.examDetails.isRandomOrderOfCourceQuestionSelectionEnabled;
      user["markPerQuestions"] = this.examDetails.markPerQuestions;
      user["enableNegativeMarkingOption"] =
        this.examDetails.enableNegativeMarkingOption;
      user["negativeMarkDeduction"] = this.examDetails.negativeMarkDeduction;
      user["numberOfAttemptsPerStudent"] =
        this.examDetails.numberOfAttemptsPerStudent;
      user["numberofMcqandTrueorFalse"] = this.examDetails.numberOfMCQuestions;
      user["numberOfEassyQuestions"] = this.examDetails.numberOfEassyQuestions;
      user["passMark"] = this.examDetails.passMark;
      user["id"] = this.examDetails._id;
      axios({
        url: "/setting/exam/edit",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.editingitem = "";
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    statusChange() {
      axios({
        url: "/setting/exam/status/change",
        method: "POST",
        data: {
          id: this.examDetails._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Status Changed Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    deleteItem() {
      axios({
        url: "/setting/exam/delete",
        method: "POST",
        data: {
          id: this.examDetails._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.dialogDelete = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>
  